@import '../../../cremedelamer2_base/scss/_theme-bootstrap.scss';

// Neue Haas Unica Pro
@font-face {
  font-family: 'Neue Haas Unica Pro';
  src: url('#{$netstorage-font-path}NeueHaasUnicaPro/NeueHaasUnicaPro-Regular.woff2')
    format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

// Bodoni
@font-face {
  font-family: 'Bodoni Ant';
  src: url('#{$netstorage-font-path}BodoniAnt/BodoniAnt-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

$font--lamer-headline: 'Bodoni Ant';
$font--neue-haas-unica-pro: 'Neue Haas Unica Pro';
$font--text: $font--neue-haas-unica-pro, sans-serif;
$font--heading: $font--lamer-headline, serif;
$font--lamer-text: 'Bodoni';
$font--subheading: $font--lamer-text, serif;

// OVERRIDDEN BASE STYLES

body {
  font-family: $font--text;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font--heading;
}
.font--text {
  font-family: $font--text;
}
.font--heading {
  font-family: $font--heading;
}
