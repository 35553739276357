.waitlist-form {
  &__container {
    padding-bottom: 0;
    .waitlist-form__header {
      font-size: 38px;
      @include breakpoint($medium-up) {
        font-size: 55px;
      }
    }
  }
  &__email {
    margin-bottom: 10px;
  }
  .tooltip--waitlist {
    .waitlist_legal {
      font-size: 13px;
      padding-top: 5px;
      bottom: 75%;
      line-height: 1;
      @include breakpoint($medium-up) {
        line-height: normal;
      }
    }
  }
  &__success {
    .waitlist-form__header {
      font-size: 36px;
      @include breakpoint($medium-up) {
        font-size: 55px;
      }
    }
  }
}
.not-logged-in {
  .sticky {
    .site-header {
      &__menu-list {
        @include breakpoint($medium-up) {
          margin-#{$rdirection}: 200px;
        }
      }
    }
  }
}
.site-header {
  &__utility {
    font-family: $font--text;
    .gnav-util__content--signup {
      .site-email-signup {
        &__fields {
          &--email,
          &--sms {
            width: 96%;
          }
        }
        &__subtitle {
          width: 100%;
          margin-bottom: 0;
        }
        .site-sms-signup {
          &__terms-conditions__more {
            margin-bottom: 10px;
          }
        }
      }
      .tooltiptext-over {
        bottom: 100%;
        top: auto;
        line-height: 1;
      }
    }
    .cart-block__title__text {
      &--empty {
        display: inline-block;
        white-space: normal;
        width: 67%;
      }
      &--added {
        width: 80%;
      }
    }
  }
  &__account-menu-select {
    &-selectBox-dropdown-menu {
      width: 170px !important;
    }
  }
  &__menu__mobile-util-links {
    font-family: $font--text;
  }
}
.email-unsubscribe {
  max-width: 1024px;
  margin: 0 auto 20px;
  padding: 0 20px;
  input[type='text'] {
    width: 65%;
    border-radius: 0;
    height: 35px;
    border: 1px solid $color-black;
    &::placeholder {
      font-size: 14px;
    }
    @include breakpoint($medium-up) {
      width: auto;
    }
  }
  input[type='submit'] {
    font-size: 14px;
    border-radius: 0;
    border: 1px solid $color-black;
    padding-bottom: 9px;
    padding-top: 8px;
    font-weight: 600;
    min-width: 100px;
  }
  .label-class {
    .label-content {
      display: none;
    }
  }
  .unsubscribe-message {
    margin-bottom: 0;
  }
}
.section-stores {
  .store-locator {
    &__form-column {
      .store,
      .spa {
        display: none;
      }
      &--submit {
        padding-top: 20px;
        @include breakpoint($landscape-up) {
          padding-top: 0;
        }
      }
    }
  }
}
.sticky__live-chat {
  &__mobile {
    position: fixed;
    bottom: 30%;
    #{$rdirection}: 3px;
    text-align: center;
    z-index: 1;
    &--link {
      border: 1px solid $color-gray;
      border-radius: 50%;
      width: 25px;
      height: 25px;
      display: block;
      background: $color-white;
      img {
        vertical-align: top;
        margin-top: 4px;
      }
    }
  }
}
.customer-service_columns_container {
  .optanon-show-settings {
    text-decoration: underline;
  }
}
#onetrust-pc-sdk {
  .ot-pc-header {
    .ot-pc-logo {
      display: none;
      @include breakpoint($medium-up) {
        display: table-cell;
      }
    }
  }
}
.section-offers {
  .signup-tout-wrapper {
    .signup-tout-background-image {
      min-height: 625px;
      @include breakpoint($medium-up) {
        min-height: 300px;
      }
    }
  }
}
.section-review {
  .product-full__details {
    #pr-write {
      .p-w-r {
        .pr-header-required {
          display: block;
        }
      }
    }
  }
}
.pr-rating-form-group {
  .pr-rating-stars {
    input[type='radio'] {
      & ~ label {
        &::before {
          visibility: hidden;
        }
      }
    }
  }
}
.site-footer {
  .menu-ref {
    &:first-child {
      float: #{$ldirection};
      .last {
        line-height: 1.5;
        margin-top: 15px;
        width: 95%;
      }
    }
    &:nth-child(2) {
      width: 40%;
      .menu__item {
        &.last {
          color: $color-gray;
          line-height: 1.5;
          margin-top: 12px;
        }
      }
    }
  }
  &__country-chooser-wrapper {
    @include breakpoint($medium-up) {
      bottom: 40px;
    }
  }
}
.customer-privacy-policy-wrapper {
  .customer-service__content {
    width: auto;
    table {
      display: table-row;
    }
  }
  .cardlink_table_1,
  .cardlink_table_2,
  .cardlink_table_3 {
    float: none;
    margin-bottom: 5%;
    width: 100%;
    @include breakpoint($medium-up) {
      float: #{$ldirection};
    }
  }
  .cardlink_table_1,
  .cardlink_table_3 {
    @include breakpoint($medium-up) {
      width: 32%;
    }
  }
  .cardlink_table_2 {
    @include breakpoint($medium-up) {
      width: 36%;
      padding: 0 2%;
    }
  }
}
.loyalty__market {
  &__benifits {
    margin-top: 20px;
    font-size: 14px;
    padding: 0 20px;
    &__text {
      margin-bottom: 0;
    }
  }
  &__status__content__field {
    &:last-child {
      margin-top: 7px;
    }
  }
  &__header__text {
    bottom: 5px;
    @include breakpoint($medium-up) {
      bottom: 60px;
    }
  }
  .basic-responsive {
    .div_content_title_first {
      font-size: 35px;
      text-align: center;
      letter-spacing: 0.2em;
      @include breakpoint($medium-up) {
        margin-top: 50px;
      }
    }
  }
  .product-grid-wrapper {
    .product-carousel {
      padding-bottom: 25px;
    }
    .product-grid--carousel {
      border-bottom: 2px solid $color-black;
      .slick-track {
        width: max-content;
        margin: 0 auto;
      }
    }
  }
}
.mpp-tout__content {
  .mpp-tout {
    &__body {
      font-family: $font--text;
      opacity: 1;
    }
    &__headline {
      font-family: $font--heading;
    }
  }
}
.content-block-tout__text-inner {
  .content-block-tout {
    &__text-headline {
      font-family: $font--heading;
    }
    &__text-body {
      font-family: $font--text;
    }
  }
}
.content-block-collapsible__headline {
  font-family: $font--heading;
}
.customer-service__menu {
  .menu__item {
    .menu__link--lvl-1 {
      font-family: $font--text;
    }
  }
}
.sign-in-page {
  .return-user,
  .new-account {
    .section-head__header {
      font-family: $font--neue-haas-unica-pro;
    }
  }
}
.account-section__nav {
  .sidebar-menu__item__title {
    font-family: $font--text;
  }
}
.content {
  .olapic-block {
    &__headline {
      font-family: $font--heading;
    }
    &__body {
      font-family: $font--text;
    }
  }
  .signup-tout-wrapper {
    .signup-tout-content {
      overflow: initial;
      #email-signup__form {
        margin: 0;
        @include breakpoint($medium-up) {
          margin: 35px 0 10px;
        }
      }
      &__panel--right {
        .headline {
          p {
            font-family: $font--heading;
          }
        }
        .subtitle {
          p {
            font-family: $font--text;
            @include breakpoint($medium-up) {
              line-height: normal;
            }
          }
        }
      }
    }
    .signup-tout-background-image {
      min-height: 650px;
      @include breakpoint($medium-up) {
        min-height: 350px;
      }
    }
  }
}
.customer-service__collapsible-block {
  font-family: $font--subheading;
  .collapsible-block__content {
    font-family: $font--text;
  }
}
.site-header,
.customer-service__subtitle {
  font-family: $font--lamer-text;
}
#pr-reviewdisplay {
  .p-w-r {
    .pr-review-display {
      .pr-rd-main-header {
        .pr-rd-review-header-contents {
          &::before {
            content: 'ΦΙΛΤΡΑΡΕΤΕ ΜΕ:';
          }
        }
        .pr-rd-main-header-search-sort {
          .pr-rd-review-header-sorts {
            .pr-rd-sort-group {
              &::before {
                content: 'ΤΑΞΙΝΟΜΗΣΗ ΚΑΤΑ:';
              }
            }
            .pr-rd-sort {
              @include breakpoint($medium-up) {
                width: 58%;
              }
            }
          }
        }
      }
      .pr-review {
        .pr-rd-footer {
          .pr-helpful-yes {
            .pr-helpful-count {
              &::before {
                content: 'ΝΑΙ';
              }
            }
          }
          .pr-helpful-no {
            .pr-helpful-count {
              &::before {
                content: 'ΟΧΙ';
              }
            }
          }
        }
      }
      .pr-modal-content {
        .pr-flag-review-btn {
          margin-bottom: 15px;
          @include breakpoint($medium-up) {
            margin-bottom: 0;
          }
        }
      }
    }
    .pr-review-snapshot {
      .pr-review-snapshot-block-container {
        .pr-review-snapshot-block-pros {
          .pr-snapshot-tag-def {
            display: none;
          }
        }
      }
    }
  }
}
.spp_reviews {
  &.page-spp {
    .p-w-r {
      .pr-snippet {
        .pr-snippet-stars-png {
          .pr-snippet-rating-decimal {
            &::after {
              content: ' ΑΠΟ 5 ΑΣΤΕΡΙΑ';
            }
          }
        }
      }
    }
  }
}
.product-results-sort,
.mpp-container {
  .mpp-sort {
    select {
      width: 155px;
    }
  }
}
.mpp-container,
.spp__container,
.product-grid {
  .product-flag__badge {
    width: 55px;
    height: 55px;
    @include breakpoint($medium-up) {
      width: 65px;
      height: 65px;
    }
  }
}
.spp__container {
  .product-flag {
    top: 25px;
    @include breakpoint($medium-up) {
      top: 60px;
    }
  }
}
.store-locator__form {
  &--filters {
    .button--transparent {
      white-space: initial;
    }
  }
}
span {
  &.font-color--light {
    opacity: 1;
  }
}
.section-customer-service-sales-terms {
  .cs-content-block__content {
    table {
      table-layout: fixed;
      width: 100%;
      @include breakpoint($medium-up) {
        width: 80%;
      }
      tr {
        td {
          word-break: break-word;
          &:first-child {
            width: 40%;
          }
        }
      }
    }
  }
}
.section-chatprivacypolicy {
  .site-header__container,
  .site-content__offer-banner,
  .site-footer {
    display: none;
  }
}
#index,
#orders,
#profile_preferences,
#purchases,
#loyalty,
#settings,
#viewcart {
  .sticky {
    .site-header {
      &__menu-list {
        @include breakpoint($medium-up) {
          margin-#{$rdirection}: 310px;
        }
      }
    }
  }
}
.orders-page {
  .orders-list__item {
    .order-products {
      .product {
        .cart-item__price {
          padding-#{$rdirection}: 0;
        }
      }
    }
  }
}
.lp_desktop {
  .lpc_window {
    #{$ldirection}: 20px;
  }
}
.customer-service-returns-page {
  ul {
    li {
      @include breakpoint($medium-up) {
        margin-#{$ldirection}: 0;
      }
    }
  }
}
.hp-chat-now {
  .link {
    color: $color-white;
    font-size: 11px;
    border-bottom: none;
  }
}
.gnav-util--account {
  .password-field__rules {
    font-size: 11.5px;
    padding-#{$ldirection}: 0;
  }
}
.page-product-skincolor {
  .ff-quiz__slide--2 {
    .ff-quiz__question {
      top: -185px;
    }
  }
  .ff-quiz__footer {
    padding-top: 65px;
  }
  .ff-results {
    .ff-results-buttons {
      .ff-button {
        padding: 3px;
        line-height: normal;
        height: 4em;
        font-size: 10.3px;
        &.ff-button--back {
          padding: 10px;
        }
      }
    }
  }
}

.site-footer-pc {
  &__column-title {
    font-family: $font--lamer-text;
  }
}

.font--subheading {
  font-family: $font--lamer-text;
}
.section-signup {
  .signup-tout-wrapper {
    .signup-tout-content {
      .headline {
        font-family: $font--lamer-text;
      }
      .subtitle {
        font-family: $font--text;
        p {
          font-family: $font--text;
        }
      }
      #email-signup__form {
        margin: 35px auto 10px;
      }
    }
  }
}

.login__container {
  .tooltip {
    display: block;
  }
}
