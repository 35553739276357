.active-panel-shipping {
  .site-content {
    .checkout {
      &__panel-subtitle {
        display: none;
      }
      &__content {
        @include breakpoint($medium-up) {
          padding-#{$rdirection}: 30px;
        }
      }
    }
    .checkout-panel {
      &--shipping-edit-address {
        .checkout {
          &__subtitle {
            font-size: 20px;
          }
        }
        .address-form {
          .address3 {
            margin-bottom: 0;
            display: none;
          }
          .address1 {
            width: 100%;
            margin-bottom: 20px;
          }
          .delivery-instructions {
            .field {
              @include breakpoint($medium-up) {
                width: 97.7%;
              }
            }
          }
          .form-item {
            &.default-shipping {
              margin-top: 15px;
              @include breakpoint($medium-up) {
                margin-top: 20px;
                margin-bottom: 25px;
              }
            }
          }
          .country_container,
          .phone-label {
            &__container {
              display: none;
            }
          }
          .phones {
            .phone-1 {
              display: none;
            }
            .phone-2 {
              width: 100%;
              margin-bottom: 10px;
            }
          }
          .bill-to-shipping {
            border-top: 1px solid $color-lighter-gray;
            margin-top: 20px;
            padding-top: 20px;
          }
        }
        .checkout-email {
          display: none;
        }
        .select-menu {
          .address-options {
            width: 100%;
            &:first-child {
              border-top: none;
            }
            .edit-address-link {
              margin-top: 0;
              a {
                @include breakpoint($medium-up) {
                  font-size: 11px;
                  min-width: 140px;
                  border: 1px solid $color-lighter-gray;
                  text-decoration: none;
                  color: $color-black;
                  padding: 10px 25px;
                  border-radius: 5px;
                  &:hover {
                    background-color: $color-green;
                  }
                }
              }
            }
          }
        }
      }
      &--order-summary {
        .checkout-panel {
          &__header {
            margin-top: 10px;
          }
          &__heading {
            margin-bottom: 0;
            @include breakpoint($medium-up) {
              letter-spacing: initial;
            }
          }
        }
      }
      &--links {
        @include breakpoint($medium-up) {
          border-bottom: 1px solid $color-light-gray;
        }
      }
      .corporate-invoice {
        .corporate_invoice {
          max-width: initial;
          margin-top: 0;
          margin-bottom: 15px;
          .corporate_options {
            .form-item {
              float: #{$ldirection};
              @include breakpoint($medium-up) {
                width: 100%;
                label {
                  font-size: 12.5px;
                  line-height: 2.4;
                }
              }
              .corporate-vat-lookup {
                width: 60%;
                float: #{$rdirection};
                @include breakpoint($medium-up) {
                  width: 27%;
                  margin-bottom: 0;
                }
              }
              &.corporate-vat-lookup-instructions {
                clear: #{$rdirection};
                margin-top: 25px;
                p {
                  margin-bottom: 0;
                }
              }
              .corporate-tax-id,
              .corporate-name,
              .corporate-address {
                background-color: $color-dark-smoke;
              }
            }
            .corporate-profession,
            .revenue-office {
              @include breakpoint($medium-up) {
                width: 50%;
              }
            }
          }
        }
      }
      .tooltip-margin {
        @include breakpoint($medium-up) {
          width: 97.6%;
        }
        span {
          &:first-child {
            width: 20px;
            height: 20px;
            display: inline-block;
            border-radius: 50%;
            background: $color-black;
            color: $color-white;
            font-size: 13px;
            text-align: center;
            line-height: 1.4;
            margin-#{$rdirection}: 10px;
            vertical-align: middle;
            font-weight: bold;
          }
        }
      }
      &--shipping {
        .billing_address_section {
          border-top: 1px solid $color-lighter-gray;
          margin-top: 20px;
          @include breakpoint($medium-up) {
            margin-top: 0;
          }
          .shipping-edit-address-content {
            &__title {
              border-bottom: none;
              border-top: none;
              font-size: 20px;
              padding-bottom: 0;
            }
          }
        }
        .billing-address-from-fields {
          .checkout {
            &__subtitle {
              border-top: 1px solid $color-lighter-gray;
              width: 97.6%;
              margin-top: 32px;
              padding-top: 60px;
            }
          }
          .addresses {
            .address1 {
              margin-bottom: 20px;
            }
            .billing-address3 {
              display: none;
            }
          }
        }
        section {
          &.gift-options-content {
            @include breakpoint($medium-up) {
              width: 97.6%;
              border-bottom: 1px solid $color-lighter-gray;
            }
            padding: 0;
            border-bottom: none;
            border-top: 1px solid $color-lighter-gray;
            .checkout-panel {
              &__header {
                margin-top: 10px;
                margin-bottom: 0;
                @include breakpoint($medium-up) {
                  margin-bottom: 7px;
                }
              }
              &__heading {
                width: 100%;
              }
            }
            .gift-message {
              &__container {
                .card-message {
                  .giftmessage_count {
                    width: 100%;
                  }
                }
              }
            }
            &.giftwrap-border-bottom {
              border-bottom: 1px solid $color-lighter-gray;
            }
          }
          .icon {
            @include breakpoint($medium-up) {
              padding-#{$rdirection}: 10px;
            }
          }
        }
        .address-form {
          .phone-label {
            &__container {
              margin-bottom: 7px;
            }
          }
        }
        #loyalty-info {
          padding-bottom: 3px;
          @include breakpoint($medium-up) {
            border-bottom: 1px solid $color-lighter-gray;
            width: 97.6%;
          }
          .create-loyalty-account {
            padding-top: 10px;
            .form-item {
              width: auto;
            }
            .label-content {
              display: inline-block;
              width: 90%;
              vertical-align: top;
            }
          }
          .checkout-panel {
            &__content {
              margin-top: 10px;
            }
            &__header {
              border-top: 1px solid $color-light-gray;
              margin-top: 0;
              padding-top: 15px;
              margin-bottom: 4px;
              @include breakpoint($medium-up) {
                border-top: none;
                padding-top: 9px;
                margin-bottom: 3px;
              }
            }
          }
          .promotions-content--header {
            width: 100%;
            margin-bottom: 0;
          }
          .toggle-icon {
            margin-top: 5px;
          }
          .icon--minus {
            margin-top: 0;
          }
        }
      }
    }
  }
  section {
    .checkout {
      &__content {
        .checkout {
          &__panel-title {
            font-size: 25px;
          }
        }
        .shipping__delivery-address {
          font-size: 20px;
        }
        .checkout-panel {
          &--links {
            margin-bottom: 100px;
            &.promotions-content {
              border-bottom: none;
              margin-bottom: 0;
              @include breakpoint($medium-up) {
                border-bottom: 1px solid $color-lighter-gray;
                border-top: none;
                width: 97.6%;
              }
              .checkout-panel {
                &__header {
                  margin-bottom: 5px;
                }
              }
            }
            .icon {
              @include breakpoint($medium-up) {
                padding-#{$rdirection}: 10px;
              }
            }
          }
          .email_promotions {
            max-width: initial;
            margin-top: 16px;
            .sms-mobile {
              width: 100%;
            }
            .tooltip {
              .tooltiptext-under {
                bottom: 100%;
                top: initial;
              }
            }
          }
          .email-and-sms-promotions {
            .promotions-container {
              .checkout__subtitle {
                display: block;
              }
              p {
                display: none;
              }
            }
            .checkout-panel {
              &__header {
                display: none;
              }
            }
          }
          &--shipmethod {
            &.display {
              border-top: none;
              border-bottom: none;
              select {
                @include breakpoint($medium-up) {
                  width: 97.6%;
                  margin-bottom: 30px;
                }
                height: 45px;
                option {
                  padding: 5px 0;
                }
              }
            }
          }
          &--shipping-edit-address {
            border-bottom: none;
            @include breakpoint($medium-up) {
              padding-bottom: 0;
            }
            .address-to-use {
              margin-bottom: 30px;
              .ship_group_1 {
                margin-bottom: 20px;
              }
            }
            .select-menu {
              margin-bottom: 15px;
            }
          }
          .new-address {
            .label-content {
              font-weight: normal;
            }
          }
          &--shipping {
            .sub-section {
              &.new-address {
                padding-bottom: 20px;
                padding-top: 25px;
                margin-bottom: 0;
                border-top: none;
                @include breakpoint($medium-up) {
                  padding-top: 0;
                  padding-bottom: 30px;
                }
                h3 {
                  padding-top: 50px;
                }
              }
            }
          }
        }
      }
    }
  }
}
.active-panel-review {
  .checkout-single-page {
    .checkout {
      &__content {
        @include breakpoint($medium-up) {
          padding-#{$rdirection}: 36px;
        }
      }
      &__sidebar {
        .checkout-panel {
          &--shipmethod {
            @include breakpoint($medium-up) {
              display: none;
            }
          }
        }
      }
    }
    .checkout-panel {
      .payment-type-field {
        .checkout {
          &__heading {
            font-size: 25px;
          }
        }
        .content {
          .checkout {
            &__subtitle {
              text-align: center;
              margin-bottom: 30px;
              @include breakpoint($medium-up) {
                text-align: initial;
                margin-bottom: 0;
              }
            }
          }
          &:last-child {
            .checkout-order {
              &__terms {
                margin-top: 20px;
                &::before {
                  margin-top: 4px;
                  @include breakpoint($medium-up) {
                    margin-top: 30px;
                  }
                }
                .label-content {
                  margin-top: 0;
                  margin-bottom: 70px;
                  @include breakpoint($medium-up) {
                    margin-top: 25px;
                    margin-bottom: 0;
                  }
                }
              }
            }
          }
        }
        .messages-container {
          padding: 0;
        }
        .checkout__subtitle {
          margin: 0;
          padding: 0;
          &__creditcard {
            padding: 10px 0;
          }
        }
        .payment-type {
          border-top: 1px solid $color-light-gray;
          margin: 0;
          @include breakpoint($medium-up) {
            border-top: none;
          }
          &.iris-payment {
            @include breakpoint($medium-up) {
              border-top: 1px solid $color-light-gray;
              border-bottom: 1px solid $color-light-gray;
            }
          }
          .radio {
            padding: 20px 0;
            img {
              position: initial;
              float: #{$rdirection};
            }
          }
          .installopts {
            border-bottom: 1px solid $color-light-gray;
            .field {
              width: 100%;
              height: 50px;
              margin-bottom: 15px;
              margin-top: 5px;
              color: $color-light-gray;
            }
          }
        }
        .payment-description {
          margin-top: 25px;
          margin-bottom: 5px;
          border: 1px solid $color-light-gray;
          padding: 30px 15px;
          color: $color-light-gray;
          .cardlink-eligibility {
            color: $color-light-gray;
          }
        }
      }
      section {
        &.checkout-panel {
          &--giftwrap {
            .gift-options-display-content {
              display: block;
              .icon {
                @include breakpoint($medium-up) {
                  padding-#{$rdirection}: 30px;
                }
              }
            }
          }
        }
      }
      .content,
      .shipping-details {
        .gift-options-display-content {
          display: none;
        }
      }
      &--shipping {
        .shipping-address {
          padding-top: 0;
          padding-bottom: 0;
          border-top: none;
          @include breakpoint($medium-up) {
            padding-top: 20px;
            padding-bottom: 20px;
            border-top: 1px solid $color-lighter-gray;
          }
        }
        .view-address {
          padding-top: 0;
          padding-bottom: 0;
          @include breakpoint($medium-up) {
            padding-top: 35px;
            padding-bottom: 20px;
          }
        }
        &.active {
          overflow: initial;
          .checkout-panel--links {
            border-top: 1px solid $color-lighter-gray;
            margin-bottom: 0;
          }
          .tooltip-margin {
            display: block;
            span {
              &:first-child {
                width: 20px;
                height: 20px;
                display: inline-block;
                border-radius: 50%;
                background: $color-black;
                color: $color-white;
                font-size: 13px;
                text-align: center;
                line-height: 1.5;
                margin-#{$rdirection}: 10px;
                vertical-align: middle;
                font-weight: bold;
              }
            }
          }
          #loyalty-info {
            .checkout-panel__header {
              .toggle-icon {
                margin-top: 6px;
                @include breakpoint($medium-up) {
                  margin-#{$rdirection}: 15px;
                }
              }
            }
          }
          .checkout-panel--shipping-edit-address {
            .address-form {
              .address1 {
                width: 100%;
              }
              .address3 {
                display: none;
              }
              .phones {
                .phone-1 {
                  display: none;
                }
                .phone-2 {
                  width: 100%;
                }
              }
              .delivery-instructions {
                .field {
                  @include breakpoint($medium-up) {
                    width: 97.7%;
                  }
                }
              }
            }
          }
          .billing-address {
            .billing-address3 {
              display: none;
            }
          }
          .bill-display-edit {
            .button--secondary {
              @include breakpoint($medium-up) {
                text-align: center;
              }
            }
          }
          .promotions-content {
            .checkout-panel__header {
              @include breakpoint($medium-up) {
                width: 98%;
                padding-bottom: 6px;
              }
            }
            .email_promotions {
              .tooltiptext-under {
                top: initial;
                bottom: 100%;
              }
            }
          }
          .gift-options-content {
            margin-bottom: 10px;
            @include breakpoint($medium-up) {
              margin-bottom: 0;
              padding-bottom: 6px;
              padding-top: 0;
            }
          }
          .corporate-invoice {
            .corporate_invoice {
              max-width: initial;
              margin-top: 0;
              margin-bottom: 15px;
              .corporate_options {
                .form-item {
                  float: #{$ldirection};
                  @include breakpoint($medium-up) {
                    width: 100%;
                    label {
                      font-size: 12.5px;
                      line-height: 2.4;
                    }
                  }
                  .corporate-vat-lookup {
                    width: 60%;
                    float: #{$rdirection};
                    @include breakpoint($medium-up) {
                      width: 27%;
                      margin-bottom: 0;
                    }
                  }
                  &.corporate-vat-lookup-instructions {
                    clear: #{$rdirection};
                    margin-top: 25px;
                    p {
                      margin-bottom: 0;
                    }
                  }
                  .corporate-tax-id,
                  .corporate-name,
                  .corporate-address {
                    background-color: $color-dark-smoke;
                  }
                }
                .corporate-profession,
                .revenue-office {
                  @include breakpoint($medium-up) {
                    width: 50%;
                  }
                }
              }
            }
          }
        }
        &.finished {
          .tooltip-margin {
            display: none;
          }
        }
      }
      &--order-summary {
        border-bottom: none;
        .button {
          &--secondary {
            color: $color-black;
          }
        }
        .checkout-panel {
          &__heading {
            @include breakpoint($medium-up) {
              letter-spacing: initial;
            }
          }
        }
      }
      &--offer-code {
        border-top: 1px solid $color-light-gray;
      }
      &--giftwrap {
        .gift-options-display-content {
          &__heading {
            margin-top: 10px;
            @include breakpoint($medium-up) {
              margin-bottom: 10px;
            }
            .icon {
              margin-top: 8px;
            }
          }
        }
        .giftwrap-content {
          .edit {
            margin-bottom: 10px;
          }
        }
      }
      &--links {
        margin-bottom: 75px;
        @include breakpoint($medium-up) {
          border-bottom: 1px solid $color-light-gray;
          margin-bottom: 0;
        }
      }
    }
    .cart-items__item {
      &--total {
        width: auto;
        @include breakpoint($medium-up) {
          width: 25%;
        }
      }
    }
  }
  section {
    .checkout-panel {
      .payment-type-field {
        .checkout {
          &__subtitle {
            border-bottom: none;
          }
        }
        #continue-btn {
          .form-submit {
            @include breakpoint($medium-up) {
              margin: 10px 0 20px 0;
            }
          }
        }
      }
      &--shipping {
        &.active {
          &.checkout-panel {
            @include breakpoint($medium-up) {
              border-bottom: none;
            }
          }
        }
      }
    }
    .checkout {
      &__content {
        .checkout-panel {
          &--shipping {
            .shipping-address {
              .button {
                &--secondary {
                  color: $color-black;
                }
              }
            }
          }
          &--review {
            .view-address {
              .button {
                &--secondary {
                  color: $color-black;
                }
              }
            }
          }
        }
      }
    }
  }
}
.checkout {
  &--confirmation-page {
    .checkout {
      &__header {
        padding: 15px 0;
      }
    }
    .non__loyalty__panel__learnmore {
      margin-bottom: 15px;
    }
    .loyalty-join-content {
      .checkout-panel__content__label {
        padding-top: 10px;
      }
    }
  }
}
.active-panel-shipping,
.active-panel-review,
.active-panel-registration {
  .checkout-progress-bar {
    &__list-item {
      @include breakpoint($small-med-only) {
        width: 27.5%;
      }
      &--review {
        margin-#{$ldirection}: 30px;
        @include breakpoint($small-med-only) {
          margin-#{$ldirection}: 12px;
        }
      }
      &--payment {
        margin-#{$ldirection}: 18px;
        @include breakpoint($small-med-only) {
          margin-#{$ldirection}: 4px;
          width: auto;
        }
      }
      &--shipping {
        @include breakpoint($small-med-only) {
          margin-#{$ldirection}: 0;
        }
      }
    }
  }
  section {
    &.checkout-single-page {
      @include breakpoint($small-med-only) {
        padding-#{$ldirection}: 24px;
        padding-#{$rdirection}: 24px;
      }
    }
  }
}
#viewcart {
  .viewcart {
    .checkout-panel {
      &--shipmethod {
        .ship-method-groups {
          display: block;
          span {
            display: block;
          }
        }
      }
    }
  }
  .checkout-panel--links {
    .checkout-panel__heading {
      font-size: 19px;
      @include breakpoint($medium-up) {
        font-size: 20px;
      }
      .toggle-icon {
        font-size: 20px;
      }
    }
  }
}
.order-summary-content {
  .total {
    &.label {
      font-weight: initial;
      width: 80%;
    }
  }
  .total-container {
    &__content {
      .label {
        font-weight: 700;
        width: 65%;
      }
    }
  }
}
.active-panel-shipping,
.active-panel-review {
  .back-to-top {
    &.active {
      &:before {
        background: $color-black;
        color: $color-white;
        @include breakpoint($medium-up) {
          width: 29px;
        }
      }
    }
  }
  .checkout-panel {
    &--viewcart {
      .checkout-panel {
        &__heading {
          span {
            font-family: $font--arial-times-lino;
          }
        }
      }
    }
  }
}
.active-panel-cart {
  .checkout {
    &__sidebar {
      .checkout-panel {
        &--links {
          border-top: none;
        }
      }
    }
  }
  .viewcart {
    .checkout {
      &__sidebar {
        margin: 0.5em;
        @include breakpoint($medium-up) {
          margin: 0;
        }
      }
    }
  }
}
.samples-panel {
  &__title {
    text-align: center;
    font-size: 20px;
    margin-top: 15px;
  }
  .samples-top,
  h3,
  p {
    text-align: center;
    color: $color-aqua-blue;
  }
  h3 {
    font-size: 20px;
    padding-top: 30px;
    clear: #{$ldirection};
    margin-bottom: 5px;
  }
  #redeem_loyalty {
    .sample-products {
      border-bottom: 1px solid $color-light-gray;
      @include breakpoint($medium-up) {
        height: 550px;
      }
      .details {
        width: 100%;
        .product-img {
          width: 100%;
        }
        .product-name {
          clear: #{$ldirection};
          text-align: center;
          @include breakpoint($medium-up) {
            min-height: 45px;
          }
        }
        .product-desc {
          text-align: center;
          margin-bottom: 20px;
          @include breakpoint($medium-up) {
            min-height: 45px;
          }
        }
      }
      .sample-sku-item {
        fieldset {
          text-align: center;
          margin-bottom: 30px;
          .sample-select-button {
            border: 1px solid $color-black;
            padding: 6px 30px 7px;
            background: $color-black;
            color: $color-white;
            border-radius: 3px;
          }
        }
      }
      .product-list {
        .product {
          border-bottom: none;
          @include breakpoint($medium-up) {
            width: auto;
            &:hover {
              &:after {
                z-index: -1;
                box-shadow: none;
              }
            }
          }
        }
      }
      .slick-arrow {
        &:before {
          font-weight: 900;
          font-size: 20px;
        }
      }
    }
    .samples-buttons {
      clear: both;
      padding: 0 20px;
      .form-submit {
        float: #{$rdirection};
        margin-top: 20px;
        @include breakpoint($medium-up) {
          float: none;
        }
        @include breakpoint($tablet-only) {
          float: #{$rdirection};
        }
      }
      .return-link {
        margin-bottom: 10px;
        clear: both;
      }
      .add-button {
        display: block;
        float: #{$rdirection};
      }
    }
  }
  .loyalty_detail_message {
    border-bottom: 1px solid $color-light-gray;
    padding-bottom: 30px;
    color: $color-black;
    @include breakpoint($medium-up) {
      padding-bottom: 40px;
      margin-bottom: 0;
    }
  }
}
.back-to-top {
  &.active {
    &:before {
      background: $color-black;
      color: $color-white;
    }
  }
}
.active-panel-registration {
  .checkout-panel--registration {
    .registration-content {
      &__heading {
        font-size: 20px;
        font-weight: bold;
      }
      &__pre {
        ul {
          margin-#{$ldirection}: 5px;
          li {
            &:nth-of-type(1) {
              margin-top: 10px;
            }
          }
        }
      }
    }
  }
  .checkout-registration {
    .form-item {
      margin-bottom: 20px;
    }
  }
  .sticky {
    .site-header {
      &__menu-list {
        margin-#{$rdirection}: 200px;
      }
    }
  }
}
