.address-overlay {
  .address-form {
    .form-field {
      textarea {
        padding: 10px 10px 0;
      }
      &.postalcode {
        &_state-field {
          @include breakpoint($medium-up) {
            width: 48%;
            padding-#{$rdirection}: 8px;
            float: #{$ldirection};
          }
          input[type='text'] {
            width: 100%;
          }
        }
      }
    }
    .address4 {
      .field {
        width: 100%;
      }
    }
  }
}

.account-address {
  .account-settings {
    &__container {
      .section-header {
        &__header {
          @include breakpoint($medium-up) {
            width: auto;
          }
        }
      }
    }
  }
}

.favorites-page {
  &__content {
    .product {
      .product-item {
        &__img {
          img {
            width: 100%;
            @include breakpoint($medium-up) {
              width: auto;
            }
          }
        }
      }
    }
  }
}
.orders-page {
  &__content {
    .orders-list {
      &__item {
        span {
          margin-#{$rdirection}: 20px;
        }
      }
      &__table {
        &--order-status {
          width: auto;
        }
        &--order-total {
          @include breakpoint($medium-up) {
            width: 18%;
          }
        }
        &--order-accordion {
          display: none;
        }
        &--order-item-count {
          display: none;
          @include breakpoint($medium-up) {
            display: block;
          }
        }
      }
      .accordion-header {
        .orders-list {
          &__table {
            &--order-status {
              @include breakpoint($medium-up) {
                width: 14%;
              }
            }
            &--order-accordion {
              display: block;
            }
          }
        }
      }
    }
  }
}
.account__section--loyalty {
  padding: 20px;
  .account-page__header {
    font-weight: 600;
    letter-spacing: 0.2em;
    margin-bottom: 10px;
    @include breakpoint($medium-up) {
      margin-bottom: 20px;
    }
  }
  .loyalty_group {
    .loyalty_option {
      display: block;
      margin-bottom: 10px;
    }
  }
  .phone_1_container {
    .phone_1_elements {
      .label-content {
        display: none;
      }
      .field {
        width: 100%;
        height: 35px;
        border-radius: 0;
        margin-bottom: 15px;
        border: 1px solid $color-black;
        @include breakpoint($medium-up) {
          width: 50%;
        }
        &.error {
          border: 1px solid $color-red;
          color: $color-black;
        }
      }
    }
    .tooltip--loyalty-enrollment-phone {
      color: $color-red;
    }
  }
  .loyalty-enrollment {
    .address-form {
      .label-content {
        display: none;
      }
      .field {
        float: #{$ldirection};
        width: 100%;
        border-radius: 0;
        margin-#{$rdirection}: 15px;
        margin-bottom: 15px;
        border: 1px solid $color-black;
        @include breakpoint($medium-up) {
          width: 47%;
        }
      }
      .address1_container {
        .field {
          width: 100%;
          @include breakpoint($tablet-only) {
            width: 96%;
          }
          @include breakpoint($large-only) {
            width: 95.5%;
          }
          @include breakpoint($ipad-pro-portrait-only) {
            width: 96%;
          }
        }
      }
      .terms_container {
        .label-content {
          display: inline-block;
          width: 90%;
          vertical-align: top;
          a {
            color: $color-red;
          }
        }
      }
      .tooltip--loyalty {
        margin-top: 15px;
        margin-bottom: 0;
        color: $color-red;
      }
    }
  }
  fieldset {
    .form-item {
      margin-bottom: 0;
    }
  }
  .form-submit {
    float: #{$rdirection};
    padding-bottom: 7px;
    padding-top: 6px;
    font-weight: 600;
    min-width: 95px;
    border-radius: 0;
    font-size: 12px;
    margin-bottom: 12px;
    margin-top: 10px;
  }
  .loyalty-sms-code-container {
    .sms_code {
      .field {
        width: 100%;
        @include breakpoint($medium-up) {
          width: 38%;
          height: 35px;
          border-radius: 0;
          margin-bottom: 15px;
          border: 1px solid $color-black;
        }
      }
      a {
        color: $color-red;
      }
    }
    .form-submit {
      float: none;
    }
  }
  .customer-service-header {
    font-weight: 600;
    letter-spacing: 0.2em;
    margin-bottom: 10px;
  }
  .loyalty-enrollment-phone {
    .server,
    .tooltip {
      color: $color-red;
    }
  }
  .account-section__nav {
    .sidebar-menu {
      .loyalty {
        a {
          font-weight: bold;
        }
      }
    }
  }
  .error_messages {
    color: $color-red;
  }
}
.sign-in-page {
  .new-account {
    &__fieldset {
      .new-account__social {
        .social-login-section {
          margin-top: 0;
        }
      }
      .tooltip {
        .tooltiptext-over {
          top: 100%;
          font-size: 13px;
          bottom: initial;
          @include breakpoint($medium-up) {
            bottom: 100%;
            top: initial;
            padding-top: 8px;
          }
        }
      }
    }
    &__container {
      .social-login__divider {
        &:before {
          height: 1px;
          background: $color-light-gray;
          @include breakpoint($landscape-up) {
            height: 320px;
            background: $color-light-gray;
          }
        }
      }
    }
    &__item {
      .form-submit {
        &.button {
          font-family: $font--text;
        }
      }
    }
    .form-item {
      &.sign-in-btn,
      input {
        font-family: $font--text;
      }
    }
  }
  .return-user {
    .form-item {
      &.sign-up-btn,
      input {
        font-family: $font--text;
      }
    }
    &__section {
      .submit {
        .form-submit {
          font-family: $font--text;
        }
      }
    }
  }
}
.loyalty__panel__landing {
  &__user__title {
    color: $color-marine;
    text-align: center;
    text-transform: uppercase;
    font-size: 24px;
    margin-bottom: 10px;
  }
  &__user__logo {
    background-image: url(/media/export/cms/loyalty/marine_pc_benefits_logo.png);
    margin: 0 auto;
    background-repeat: no-repeat;
    height: 160px;
    width: 160px;
    background-size: 100% 100%;
    text-align: center;
  }
  &__user {
    width: auto;
    display: block;
    border-#{$rdirection}: none;
    @include breakpoint($medium-up) {
      padding: 0;
      width: 30%;
      display: inline-block;
      border-#{$rdirection}: 1px solid $color-light-gray;
    }
  }
  &__content {
    padding: 20px 20px 50px 20px;
    border-bottom: 1px solid $color-light-gray;
  }
  &__points {
    width: auto;
    display: block;
    text-align: center;
    @include breakpoint($medium-up) {
      padding: 1% 0 0 3%;
      width: 64%;
      display: inline-block;
      vertical-align: top;
      text-align: center;
    }
  }
  &__user__earned {
    margin: 0;
    font-size: 40px;
    padding-top: 35px;
    line-height: 1;
    color: $color-white;
  }
  &__user__text {
    font-size: 16px;
    color: $color-white;
    text-transform: uppercase;
    line-height: 1.2;
  }
  &__points__spend {
    font-size: 20px;
    margin-bottom: 20px;
    color: $color-black;
  }
  &__logo {
    margin-bottom: 25px;
  }
}
.account-section__content {
  .loyalty-page__content {
    @include breakpoint($medium-up) {
      width: initial;
    }
  }
}
.address-book-page__overlay {
  #cboxLoadedContent {
    padding-#{$ldirection}: 24px;
    padding-#{$rdirection}: 55px;
    @include breakpoint($medium-up) {
      padding: 30px 35px;
    }
  }
}

.password-request {
  .tooltip {
    .tooltiptext-over {
      font-size: 13px;
    }
  }
}

.change-password {
  fieldset {
    min-width: 0;
  }
  .password-field__rules {
    font-size: 11.5px;
    padding-#{$ldirection}: 0;
    @include breakpoint($medium-up) {
      font-size: 12px;
      padding-#{$ldirection}: 10px;
    }
  }
  .profile__change-password-overlay {
    .profile__reset-password {
      .profile-info {
        &__header {
          white-space: normal;
          width: 90%;
        }
        &__item {
          .button {
            width: 48%;
          }
          input {
            width: 100%;
          }
        }
      }
    }
  }
}
.account-page {
  .account-tabbed-block {
    .tabbed-block {
      &__tabs {
        display: none;
      }
      &__content {
        .account-section__header {
          display: block;
        }
      }
    }
  }
}

#settings {
  .account-setting__section {
    .account-settings__container {
      h3 {
        font-family: $font--text;
      }
    }
  }
}
